import { useMemo } from 'react'
import { BoletoModelInterface } from '@hooks/queries/useBoletos'
import DataTable from '@components/DataTable'
import useUtils from '@hooks/useUtils'
import { MUIDataTableColumnDef } from 'mui-datatables'
interface TableBoletosProps {
  data: Array<BoletoModelInterface>
  type: 'ok' | 'erro'
}

const TableBoletos = (props: TableBoletosProps) => {
  const { data, type } = props

  const { formatDate, formatCurrency } = useUtils()
  var boletos: Array<BoletoModelInterface> = []
  if (data) {
    boletos = data.map((b: any) => {
      const d = b.boleto

      return {
        ...d,
        clienteNome: d.pagador.nome,
        emissao: formatDate(d.emissao),
        vencimento: formatDate(d.vencimento),
        processamento: formatDate(d.processamento),
        pagamento: formatDate(d.pagamento),
        valor: formatCurrency(d.valor),
        log: b.log,
      }
    })
  }

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'log',
        label: 'Erro',
        options: {
          display: type === 'erro',
        },
      },
      {
        name: 'empresa',
        label: 'Empresa',
        options: {
          setCellProps: () => ({
            style: {
              width: '30px',
            },
          }),
        },
      },
      {
        name: 'documento',
        label: 'Documento',
      },
      {
        name: 'clienteNome',
        label: 'Cliente',
      },
      // {
      //   name: 'emissao',
      //   label: 'Emissão',
      //   options: {
      //     setCellProps: () => ({
      //       style: {
      //         width: '100px',
      //       },
      //     }),
      //   },
      // },
      // {
      //   name: 'processamento',
      //   label: 'Processamento',
      //   options: {
      //     setCellProps: () => ({
      //       style: {
      //         width: '100px',
      //       },
      //     }),
      //   },
      // },
      // {
      //   name: 'vencimento',
      //   label: 'Vencimento',
      //   options: {
      //     setCellProps: () => ({
      //       style: {
      //         width: '100px',
      //       },
      //     }),
      //   },
      // },
      // {
      //   name: 'pagamento',
      //   label: 'Pagamento',
      //   options: {
      //     setCellProps: () => ({
      //       style: {
      //         width: '100px',
      //       },
      //     }),
      //   },
      // },
      {
        name: 'valor',
        label: 'Valor',
        options: {
          setCellProps: () => ({
            style: {
              textAlign: 'right',
            },
          }),
        },
      },
      //   {
      //     name: 'id',
      //     label: 'Opções',
      //     options: {
      //       setCellProps: OptionStyles,
      //       customBodyRender: (value) => {
      //         const _data = findData(value)
      //         return (
      //           <Options
      //             displayUpdateButton={false}
      //             displayDeleteButton={false}
      //             extraOptions={[
      //               {
      //                 id: 'downloadBoleto',
      //                 icon: 'print',
      //               },
      //             ]}
      //             value={_data}
      //             onClick={onClickOptionsRow}
      //           />
      //         )
      //       },
      //     },
      //   },
    ],
    [type],
  )

  return (
    <div>
      <DataTable
        data={boletos}
        // isLoading={isLoading}
        // isFetching={isFetching}
        columns={columns}
        // options={options}
        // error={error?.message}
      />
    </div>
  )
}

export default TableBoletos
