import { ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
// import theme from './styles/theme'
import { theme } from '@data-c/ui'
import { ToastContainer, Flip } from 'react-toastify'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import AppRoutes from './routes'

import 'react-toastify/dist/ReactToastify.css'
import 'font-awesome/css/font-awesome.css'
import queryClient from '@services/query-cliente'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <AppRoutes />
        <ReactQueryDevtools />
      </QueryClientProvider>
      <ToastContainer transition={Flip} position="bottom-right" />
    </ThemeProvider>
  )
}

export default App
