import { TextField, TextFieldProps } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers/DatePicker'

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { Moment } from 'moment'
import 'moment/locale/pt-br'

interface DatePickerProps
  extends Omit<
    MuiDatePickerProps<string | null | undefined, Moment>,
    'renderInput'
  > {}

export default function DatePicker(props: DatePickerProps) {
  const { value, onChange } = props

  function handleOnChange(value: Moment | null, _?: string | undefined) {
    const formatedValue = value?.format('yyyy-MM-DD') || ''
    onChange(value, formatedValue)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiDatePicker
        value={value}
        onChange={handleOnChange}
        renderInput={(params: TextFieldProps) => <TextField {...params} />}
      />
    </LocalizationProvider>
  )
}
