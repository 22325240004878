import { useEffect, useState } from 'react'
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
} from '@mui/material'

import useNotification from 'hooks/useNotifications'
import { AutoCompleteProps } from 'hooks/useAutocomplete'
import useConvenio, { ConvenioModel } from '@hooks/queries/useConvenio'

interface AutoCompleteConvenioProps extends AutoCompleteProps<ConvenioModel> {
  empresaId: string
}

export default function AutoCompleteConvenio(props: AutoCompleteConvenioProps) {
  const { onChange, value, autoFocus, empresaId } = props

  const [options, setOptions] = useState<ConvenioModel[]>([])
  const [isLoading, setLoading] = useState(false)

  const notifications = useNotification()
  const { obterConvenioPorEmpresaId } = useConvenio()

  useEffect(() => {
    async function obterConvenios() {
      setLoading(true)

      try {
        const convenio = await obterConvenioPorEmpresaId(empresaId)

        setOptions(convenio.data)
      } catch (error) {
        notifications.notifyException(error)
      } finally {
        setLoading(false)
      }
    }

    if (empresaId) {
      obterConvenios()
    }
    setOptions([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresaId])

  return (
    <Autocomplete
      value={value || null}
      disablePortal
      options={options || []}
      onChange={onChange}
      getOptionLabel={(convenio: ConvenioModel) => {
        return `${convenio.convenioDesc}`
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          value={value}
          label="Convênio"
          autoFocus={autoFocus}
        />
      )}
      loadingText="Carregando..."
      loading={isLoading}
    />
  )
}
