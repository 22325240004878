import React from 'react'

import { Dialog, DialogTitle, Box, IconButton } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import Button from '@components/Button'
import { DialogActions } from '@components/Dialog'

interface DialogProps {
  isLoading?: boolean
  isOpen: boolean
  confirm(): any
  onClose?(): any
  title: string
  description: React.ReactNode
  confirmLabel: string
  cancelLabel: string
}

const ConfirmDialog = (props: DialogProps) => {
  const {
    isLoading,
    isOpen,
    confirm,
    onClose,
    title,
    description,
    confirmLabel,
    cancelLabel,
  } = props

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="sm">
      <DialogTitle sx={{ color: (theme) => theme.palette.primary.dark }}>
        {title}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <Box
        sx={{
          padding: '0px 24px',
        }}
      >
        {description}
      </Box>
      <DialogActions>
        <Button onClick={onClose}>{cancelLabel}</Button>
        <Button isLoading={isLoading} onClick={confirm}>
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmDialog.defaultProps = {
  isLoading: false,
  isOpen: false,
  confirm: () => {},
  onClose: () => {},
  title: 'Excluir Registro',
  description: 'Você quer realmente excluir este registro?',
  confirmLabel: 'Sim',
  cancelLabel: 'Não',
}

export default ConfirmDialog
