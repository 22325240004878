import { Stack } from '@mui/material'

import { useFilterApi, useFilterData } from '@data-c/providers'
import { FilterContainer } from '@data-c/ui'

import { EmpresaModel } from '@hooks/queries/useEmpresa'
import { BoletoFilters, BoletoFilters1 } from '@hooks/queries/useBoletos'
import useUtils from '@hooks/useUtils'
import { ConvenioModel } from '@hooks/queries/useConvenio'
import { tipoDataReverseMapping } from '@hooks/queries/useTemplateEmail'

import AutoCompleteEmpresas from '@components/AutoCompletes/AutoCompleteEmpresa'
import DatePicker from '@components/DatePicker'
import DropDownTipoData from '@components/Inputs/DropDownTipoData'
import AutoCompleteConvenio from '@components/AutoCompletes/AutoCompleteConvenio'

export default function Filter() {
  const { changeFilterValue } = useFilterApi()
  const { filterValues } = useFilterData<BoletoFilters, BoletoFilters1>()
  const { formatDate } = useUtils()

  return (
    <FilterContainer
      title="Opções de Filtro"
      triggerButtonProps={{ variant: 'outlined' }}
      renderLabel={(filterLabel: string, filterValue: any) => {
        if (filterLabel === 'Empresa') {
          return `${filterLabel} - ${filterValue?.nome || ''}`
        }

        if (filterLabel === 'Convênio') {
          return `${filterLabel} - ${filterValue?.convenioDesc || ''}`
        }

        if (filterLabel === 'Início') {
          return `${filterLabel} - ${formatDate(filterValue || '')}`
        }

        if (filterLabel === 'Fim') {
          return `${filterLabel} - ${formatDate(filterValue || '')}`
        }

        if (filterLabel === 'Tipo data') {
          const newFilterValue = tipoDataReverseMapping[filterValue]
          return `${newFilterValue}`
        }

        return `${filterLabel} - ${filterValue}`
      }}
    >
      <Stack spacing={2}>
        <DatePicker
          label={filterValues?.dataInicio?.label}
          onChange={(_, value) => {
            changeFilterValue('dataInicio', value)
          }}
          value={filterValues?.dataInicio?.value}
        />

        <DatePicker
          label={filterValues?.dataFim?.label}
          onChange={(_, value) => {
            changeFilterValue('dataFim', value)
          }}
          value={filterValues?.dataFim?.value}
        />

        <AutoCompleteEmpresas
          label={filterValues?.empresa?.label}
          name="empresa"
          value={filterValues.empresa?.value || null}
          onChange={(_, value: EmpresaModel | null) => {
            changeFilterValue('empresa', value)
            changeFilterValue('convenio', null)
          }}
        />

        <AutoCompleteConvenio
          label={filterValues?.convenio?.label}
          empresaId={filterValues?.empresa?.value?.id || ''}
          disabled={!filterValues?.empresa?.value}
          name="convenio"
          value={filterValues?.convenio?.value || null}
          onChange={(_, value: ConvenioModel | null) => {
            changeFilterValue('convenio', value)
          }}
        />

        <DropDownTipoData
          value={filterValues?.tipoData?.value}
          onChange={(e) => {
            const tipoData = e.target.value
            changeFilterValue('tipoData', tipoData)
          }}
        />
      </Stack>
    </FilterContainer>
  )
}
