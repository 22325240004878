import {
  useMutation,
  useQuery as useTQuery,
  useQueryClient,
} from '@tanstack/react-query'
import QueryParamsInterface from 'interfaces/QueryParamsInterface'
import useNotification from '@hooks/useNotifications'
import { AxiosError } from 'axios'
import api from '@services/api'
import QueryResponseInterface from 'interfaces/QueryResponseInterface'
import geradorUuid from '@hooks/useGeradorUuid'
import QueryObjResponseInterface from 'interfaces/QueryObjResponseInterface'

export interface RegraEnvioInterface {
  liquidado: boolean
  operacao: 'add' | 'subtract' | 'now'
  campo: 'vencimento' | 'pagamento' | 'registro'
}

export const tipoDataMapping: { [key: string]: number } = {
  vencimento: 0,
  pagamento: 1,
  registro: 2,
}

export const tipoDataReverseMapping: {
  [key: number]: 'vencimento' | 'pagamento' | 'registro'
} = {
  0: 'vencimento',
  1: 'pagamento',
  2: 'registro',
}

export interface TemplateEmailInterface {
  id?: string
  nome: string
  identificador: string
  tituloEmail?: string
  corpoEmail?: string
  designEmail?: any
  regraEnvio?: RegraEnvioInterface
  horaEnvio?: number
  dias?: number
  anexaBoleto?: boolean
  envioAutomatico?: boolean
  tipoEnvio?: 'automatico' | 'manual' | null
  template_layout_id?: number
  tipoData?: number
  quantidadeDias?: number
  somenteBoletosLiquidados?: boolean
  ativo?: boolean
  anexarPDF?: boolean
}

export interface TemplateEmailInterfacePartials {
  id: string
  nome: string
  identificador: string
  tituloEmail: string
  ativo: boolean
  envioAutomatico: boolean
}

// interface CustomQueryParamsInterface extends QueryParamsInterface {
//   params?: { tipoEnvio?: 'automatico' | 'manual' | null }
// }

export async function obterTemplates(
  params?: QueryParamsInterface,
): Promise<QueryResponseInterface<TemplateEmailInterface>> {
  const response = await api.get(`EmailAgendamento/Pesquisar/${params?.query}`)

  return response.data
}

export async function obterTemplatePorId(
  id: string,
): Promise<TemplateEmailInterface> {
  const response = await api.get<
    QueryObjResponseInterface<TemplateEmailInterface>
  >('EmailAgendamento/ObterPorId', {
    params: { id },
  })

  return response.data.data
}

export async function inserirTemplate(
  data: TemplateEmailInterface,
): Promise<TemplateEmailInterface> {
  const { id, nome, identificador } = data

  const sendData = {
    id,
    nome,
    identificador,
  }

  const response = await api.post<{ data: TemplateEmailInterface }>(
    'EmailAgendamento/Adicionar',
    sendData,
  )
  return response.data.data
}

export async function atualizarTemplate(
  data: TemplateEmailInterface,
): Promise<TemplateEmailInterface> {
  delete data.regraEnvio

  const response = await api.put('EmailAgendamento/Alterar', data, {
    params: { id: data.id },
  })
  return response.data
}

export async function salvarTemplate(
  data: TemplateEmailInterface,
): Promise<TemplateEmailInterface> {
  if (data.id) {
    return atualizarTemplate(data)
  }

  const newTemplateId = geradorUuid()
  return inserirTemplate({ ...data, id: newTemplateId })
}

export async function deletarTemplate(
  data: TemplateEmailInterface,
): Promise<TemplateEmailInterface> {
  const { id } = data
  await api.delete('EmailAgendamento/Remover', { params: { id } })
  return data
}

export function useQuery(params: QueryParamsInterface) {
  return useTQuery<QueryResponseInterface<TemplateEmailInterface>>(
    ['TEMPLATE_EMAIL', params],
    () => {
      return obterTemplates(params)
    },
    {
      staleTime: 0,
    },
  )
}

export function useSubmit() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<any, AxiosError, TemplateEmailInterface>(salvarTemplate, {
    onSuccess(_, data) {
      const flexPluralSingular = data.id ? 'alterado' : 'incluído'

      notifications.notifySuccess(
        `Agendamento ${flexPluralSingular} com sucesso`,
      )
      queryClient.invalidateQueries(['TEMPLATE_EMAIL'])
    },
    onError(error) {
      notifications.notifyException(error)
    },
  })
}

export function useDelete() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<any, AxiosError, TemplateEmailInterface>(deletarTemplate, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['TEMPLATE_EMAIL'])
      notifications.notifySuccess('Agendamento excluído com sucesso')
    },
    onError: (err) => {
      notifications.notifyException(err)
    },
  })
}

export default function useTemplateEmail() {
  return {
    useQuery,
    useSubmit,
    useDelete,
    // useQueryObterTemplatePorId,
    obterTemplatePorId,
  }
}
