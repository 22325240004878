import { useEffect, useState } from 'react'
import { Typography, Alert, Box, TextField, MenuItem } from '@mui/material'
import { Button } from '@data-c/ui'
import useTemplateEmail, {
  TemplateEmailInterface,
} from '@hooks/queries/useTemplateEmail'

interface SelectHeaderProps {
  rowsSelected: any[]
  total: number
  allSelected: boolean
  onSelectAll: () => void
  onClearSelection: () => void
  onSendSelected: (modeloId: string) => void
  sending: boolean
}

const SelectHeader = (props: SelectHeaderProps) => {
  const [modeloId, setModeloId] = useState<string>('')

  const { useQuery } = useTemplateEmail()
  const { data, isLoading } = useQuery({ query: '' })

  const {
    rowsSelected,
    total,
    allSelected,
    onSelectAll,
    onClearSelection,
    onSendSelected,
    sending,
  } = props

  useEffect(() => {
    if (data && data?.data?.length > 0) {
      setModeloId(data?.data[0]?.id || '')
    }
  }, [data])

  const handleSendSelected = () => {
    onSendSelected(modeloId)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'space-between',
        // alignItems: 'center',
        // alignContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'rows',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignContent: 'center',
          pb: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'rows',
            alignItems: 'center',
            alignContent: 'center',
            '& > *': {
              mr: 1,
            },
          }}
        >
          {allSelected && (
            <>
              <Alert severity="warning">
                Todos os <b>{total}</b> lançamentos estão selecionados
              </Alert>

              <Button onClick={onClearSelection}>Limpar Seleção</Button>
            </>
          )}

          {!allSelected && (
            <>
              <Alert severity="info">
                Você selecionou <b>{rowsSelected.length}</b> lançamento
                {rowsSelected.length > 1 ? 's' : ''} nesta página.
              </Alert>
              {total - rowsSelected.length > 0 && (
                <Box>
                  <Button onClick={onSelectAll}>
                    <Typography variant="body2">
                      Selecionar todos os outros{' '}
                      <b>{total - rowsSelected.length}</b> lançamentos.
                    </Typography>
                  </Button>
                </Box>
              )}
            </>
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            width: '380px',
            alignItems: 'center',
            alignContent: 'center',
          }}
        >
          {!isLoading && (
            <TextField
              value={modeloId || ''}
              onChange={(e) => {
                const value = e.target.value
                setModeloId(value)
              }}
              label="Agendamento"
              select
              sx={{ mr: 1 }}
            >
              {data?.data?.map((modelo: TemplateEmailInterface) => (
                <MenuItem key={modelo.id} value={modelo.id}>
                  {modelo.nome}
                </MenuItem>
              ))}
            </TextField>
          )}

          <Button
            disabled={isLoading}
            isLoading={sending}
            variant="contained"
            onClick={handleSendSelected}
          >
            Enviar
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

SelectHeader.defaultProps = {
  rowsSelected: [],
  allSelected: false,
}

export default SelectHeader
