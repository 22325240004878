import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import ErrorInterface from 'interfaces/ErrorInterface'
import apiCadastro from 'services/api-cadastro'
import { CidadeModel, UFModel } from './useCidade'
import useNotification from 'hooks/useNotifications'
import { HttpResponseInterface } from '@data-c/hooks'

export interface ClienteModel {
  id?: string
  codigo?: number
  nome: string
  nomeFantasia?: string
  logradouro?: string
  bairro?: string
  cidadeId?: string
  cidadeNome?: string
  cidadeModel?: CidadeModel | null
  cidadeUF?: string
  ufModel?: UFModel | null
  documentoId: string
  numero?: string
  complemento?: string
  cidadeUFCodigoIBGE?: string
  paisId?: string
  paisNome?: string
  cep?: string
  telefone?: string | null
  inscEstadual?: string | null
  inscEstadualId?: number | string
  email?: string
  ativo?: boolean
  createdDate?: string
  updatedDate?: string
}

export async function obterClientes(
  query: string,
): Promise<HttpResponseInterface<ClienteModel>> {
  const response = await apiCadastro.get<HttpResponseInterface<ClienteModel>>(
    `Pessoa/Pesquisar/${query}`,
  )

  return response.data
}

export async function obterClientePorId(
  id?: string,
): Promise<ClienteModel | null> {
  if (!id) return null
  const response = await apiCadastro.get<{ data: ClienteModel }>(`Pessoa/${id}`)

  return response.data.data
}

export async function createOrUpdateCliente(
  data: ClienteModel,
): Promise<HttpResponseInterface<ClienteModel>> {
  delete data.cidadeModel
  delete data.ufModel

  if (data?.id) {
    const response = await apiCadastro.put(`Pessoa/${data.id}`, data)
    return response.data
  }

  const response = await apiCadastro.post('Pessoa', data)
  return response.data
}

export async function removerCliente(
  data: ClienteModel,
): Promise<ClienteModel> {
  const response = await apiCadastro.delete(`Pessoa/${data.id}`)

  return response.data
}

export function useQueryObterClientes(query: string) {
  return useQuery<
    HttpResponseInterface<ClienteModel>,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['CLIENTE', query], () => {
    return obterClientes(query)
  })
}

export function useQueryObterClientePorId(id?: string) {
  return useQuery<ClienteModel | null, AxiosError>(['CLIENTE', id], () => {
    return obterClientePorId(id)
  })
}

export function useCreateOrUpdateCliente() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<
    HttpResponseInterface<ClienteModel>,
    AxiosError,
    ClienteModel
  >((data) => createOrUpdateCliente(data), {
    onSuccess(_, data) {
      const flexUpdateOrCreate = data?.id ? 'alterado' : 'adicionado'

      notifications.notifySuccess(`Cliente ${flexUpdateOrCreate} com sucesso!`)
      queryClient.invalidateQueries(['CLIENTE'])
      queryClient.invalidateQueries(['BOLETOS'])
    },
    onError(error) {
      notifications.notifyException(error)
    },
  })
}

export function useRemoverCliente() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<ClienteModel, AxiosError, ClienteModel>(
    (data) => removerCliente(data),
    {
      onSuccess() {
        notifications.notifySuccess('Cliente excluído com sucesso!')
        queryClient.invalidateQueries(['CLIENTE'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export default function useCliente() {
  return {
    useQueryObterClientes,
    useQueryObterClientePorId,
    useCreateOrUpdateCliente,
    useRemoverCliente,
    obterClientes,
  }
}
