import { useState } from 'react'
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from '@mui/material'

import LoginForm from './components/LoginForm'
import LicencaForm from './components/LicencaForm'

import { useNavigate } from 'react-router-dom'
import useCredentials, { LoginDataInterface } from '@hooks/useCredentials'

const Acesso = () => {
  const [activeStep, setActiveStep] = useState<number>()
  const [loginData, setLoginData] = useState<LoginDataInterface>()
  const { selecionarLicenca } = useCredentials()
  const navigate = useNavigate()

  async function handleLoginSuccess(loginData: LoginDataInterface) {
    setLoginData(loginData)

    if (loginData.empresas?.length === 1) {
      await selecionarLicenca(loginData, loginData.empresas[0].uuid)
      handleSelecionarLicensaSuccess()
      return
    }

    setActiveStep(1)
  }

  function handleSelecionarLicensaSuccess() {
    navigate('/boletos')
  }

  return (
    <Box>
      <Box
        sx={{
          width: '100%',
          textAlign: 'center',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Abel',
            color: (theme) => theme.palette.primary.main,
            fontSize: '22pt',
            fontWeight: 'bolder',
          }}
        >
          Cobrança Smart
        </Typography>
      </Box>

      <Stepper activeStep={activeStep} orientation="vertical">
        <Step key="login">
          <StepLabel optional="Informe suas credencias">Login</StepLabel>
          <StepContent>
            <LoginForm onLoginSuccess={handleLoginSuccess} />
          </StepContent>
        </Step>
        <Step key="licenca">
          <StepLabel optional="Selecione a licença que deseja utilizar">
            Licença
          </StepLabel>
          <StepContent>
            <LicencaForm
              loginData={loginData}
              onSelecionarLicencaSuccess={handleSelecionarLicensaSuccess}
            />
          </StepContent>
        </Step>
      </Stepper>
    </Box>
  )
}

export default Acesso
