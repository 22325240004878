import { useCallback, useMemo, useState } from 'react'

import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables'
import { Box, Checkbox } from '@mui/material'

import {
  DataTable,
  OptionStyles,
  CurrencyCellStyle,
  DataTableOptions as Options,
  Button,
} from '@data-c/ui'
import { findData } from '@data-c/hooks'
import { useFilterData, useFormApi } from '@data-c/providers'

import useBoletos, {
  BoletoFilters,
  BoletoFilters1,
  BoletoModelInterface,
  SortProps,
} from '@hooks/queries/useBoletos'
import useUtils from '@hooks/useUtils'
import useNotification from '@hooks/useNotifications'
import useCliente, { ClienteModel } from '@hooks/queries/useClientes'

import DataTableProps from 'interfaces/DataTableProps'

import SelectHeader from './components/SelectHeader'

interface TableProps extends DataTableProps<BoletoModelInterface> {}

export default function Table(props: TableProps) {
  const { onClickOptionsRow } = props

  const { openForm } = useFormApi<ClienteModel>()
  const { appliedValues } = useFilterData<BoletoFilters, BoletoFilters1>()

  const { empresa, convenio, ...rest } = appliedValues || {}

  const nFilters: BoletoFilters1 = {
    ...rest,
    empresaId: empresa?.id,
    convenioId: convenio?.id,
  }

  const [sort, setSort] = useState<SortProps>()
  const [selected, setSelected] = useState<any[]>([])

  const notifications = useNotification()

  const { formatDate, formatCurrency } = useUtils()

  const { useQuery, useEnviarBoletosManualmente } = useBoletos()
  const { mutateAsync: enviarBoletosManualmente, isLoading: isSendingBoletos } =
    useEnviarBoletosManualmente()
  const { obterClientes } = useCliente()
  const { data, isLoading, isFetching, error } = useQuery(nFilters, sort)

  const boletos = useMemo(() => {
    if (data) {
      return data.map((d) => {
        return {
          ...d,
          emissao: formatDate(d.dataEmissao),
          vencimento: formatDate(d.dataVencimento),
          registro: formatDate(d.dataRegistro),
          pagamento: formatDate(d.dataPagamento),
          valor: formatCurrency(d.valorTitulo),
        }
      })
    }
    return []
  }, [data, formatDate, formatCurrency])

  const totalItensSelecionaveis = useMemo(() => {
    if (data) {
      let total = 0

      for (let i = 0; i < data.length; i++) {
        if (data[i]?.pagadorEmail) {
          total += 1
        }
      }
      return total
    }
    return 0
  }, [data])

  const options: MUIDataTableOptions = useMemo(
    () => ({
      responsive: 'standard',
      sort: true,
      pagination: false,
      selectableRows: 'multiple',
      selectToolbarPlacement: 'none',
      rowsSelected: selected,
      isRowSelectable(dataIndex) {
        // if (data) {
        //   const emailCobranca = data[dataIndex]?.pagadorEmail
        //   return true
        // }
        return true
      },
      onRowSelectionChange: (_, __, rowsSelected) => {
        if (rowsSelected) setSelected(rowsSelected)
      },
      onColumnSortChange: (column, direction) => {
        console.log(column, direction)
      },
    }),
    [selected],
  )

  const handleObterClientesPorCPFCNPJ = useCallback(
    async (rowIndex: number) => {
      const cpfCnpj = boletos[rowIndex]?.pagadorCNPJCPF

      try {
        const clientes = await obterClientes(cpfCnpj)
        const existeClientes = clientes?.data?.length > 0
        const cliente = clientes?.data[0]

        if (existeClientes) {
          openForm(cliente)
          return
        }

        openForm({ documentoId: cpfCnpj, nome: '' })
      } catch (error) {
        notifications.notifyException(error)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const columns = useMemo((): MUIDataTableColumnDef[] => {
    return [
      {
        name: 'empresaNome',
        label: 'Empresa',
      },
      {
        name: 'documentoSistema',
        label: 'Documento',
        options: {
          sort: false,
        },
      },
      {
        name: 'pagadorNome',
        label: 'Cliente',
        options: {
          sort: false,
        },
      },
      {
        name: 'pagadorEmail',
        label: 'E-mail de Cobrança',
        options: {
          sort: false,
          customBodyRender: (_, tableMeta) => {
            const rowIndex = tableMeta.rowIndex
            const temPagadorEmail = Boolean(boletos[rowIndex]?.pagadorEmail)
            return (
              <Box sx={{ textAlign: 'center' }}>
                {temPagadorEmail ? (
                  boletos[rowIndex].pagadorEmail
                ) : (
                  <Button
                    variant="text"
                    onClick={() => handleObterClientesPorCPFCNPJ(rowIndex)}
                  >
                    Cadastrar
                  </Button>
                )}
              </Box>
            )
          },
        },
      },
      {
        name: 'emissao',
        label: 'Emissão',
        options: {
          sort: true,
        },
      },
      {
        name: 'registro',
        label: 'Processamento',
      },
      {
        name: 'vencimento',
        label: 'Vencimento',
      },
      {
        name: 'pagamento',
        label: 'Pagamento',
      },
      {
        name: 'valor',
        label: 'Valor Título',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      // {
      //   name: 'ultimoEnvio',
      //   label: 'Tem Envios?',
      //   options: {
      //     customBodyRender: (value: boolean) => {
      //       return <Flag isFlagged={value} type="success" />
      //     },
      //   },
      // },
      {
        name: 'id',
        label: `Opções (${boletos.length})`,
        options: {
          sort: false,
          setCellHeaderProps: () => ({
            style: {
              textAlign: 'center',
            },
          }),
          setCellProps: OptionStyles,
          customBodyRender: (value) => {
            const _data = findData(data || [], value, 'id')
            return (
              <Options
                displayUpdateButton={false}
                displayDeleteButton={false}
                value={_data}
                onClick={onClickOptionsRow as any}
                extraOptions={[
                  {
                    id: 'downloadBoleto',
                    icon: 'print',
                    visible: true,
                  },
                ]}
              />
            )
          },
        },
      },
    ]
  }, [data, onClickOptionsRow, boletos, handleObterClientesPorCPFCNPJ])

  const handleSendSelected = async (agendamentoId: string) => {
    const boletosParaEnviar = selected.map((index) => boletos[index]?.id)
    await enviarBoletosManualmente({
      agendamentoId,
      boletosIds: boletosParaEnviar,
    })
  }

  const handleSelectAll = useCallback(() => {
    const allSelecteds = boletos.map((_, index) => index)
    setSelected(allSelecteds)
  }, [boletos])

  return (
    <>
      {selected.length > 0 && (
        <SelectHeader
          allSelected={selected.length === boletos.length}
          rowsSelected={selected}
          total={totalItensSelecionaveis}
          onSelectAll={handleSelectAll}
          onClearSelection={() => {
            setSelected([])
          }}
          onSendSelected={(modeloId) => {
            handleSendSelected(modeloId)
          }}
          sending={isSendingBoletos}
        />
      )}
      <DataTable
        data={boletos}
        isLoading={isLoading}
        isFetching={isFetching}
        columns={columns}
        options={options}
        error={error?.message}
        changeSort={(column, direction) => {
          setSort({ column, direction })
        }}
        components={{
          Checkbox: Checkbox,
        }}
      />
    </>
  )
}
