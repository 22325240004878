import moment, { Moment } from 'moment'

const useDates = () => {
  const getFirstDateOfCurrentMonth = (): Moment => {
    return moment().set('date', 1)
  }

  const getCurrentDate = (): Moment => {
    return moment()
  }

  const toBrazilianDate = (value: string, invalidDate = '') => {
    if (!value) return invalidDate
    return moment(value).format('DD/MM/YYYY')
  }

  const toBrazilianDateTime = (value: string, invalidDate = '') => {
    if (!value) return invalidDate
    return moment(value).format('DD/MM/YYYY hh:mm')
  }

  return {
    getFirstDateOfCurrentMonth,
    getCurrentDate,
    toBrazilianDate,
    toBrazilianDateTime,
  }
}

export default useDates
