import {
  useQuery as useTQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import useNotifications from '@hooks/useNotifications'
import api from '@services/api'
import { AxiosError } from 'axios'
import useDownload from '@hooks/useDownload'
import { FilterOptions } from '@data-c/providers'
import moment from 'moment'
import { EmpresaModel } from './useEmpresa'
import { ConvenioModel } from './useConvenio'
import useNotification from '@hooks/useNotifications'
import { HttpResponseInterface } from '@data-c/hooks'

export const empresas: Record<number, string> = {
  1: 'LUIZ EDUARDO MANTOVANI & CIA. LTDA. - ME',
  2: 'LEMAN INFORMATICA LTDA - ME',
  3: 'L E MANTOVANI & CIA LTDA - ME',
  4: 'J N M TREINAMENTOS EM DESENVOLVIMENTO PROFISSIONAL E GERENCIAL LTDA ME',
  5: 'LEM SISTEMAS LTDA',
}

export interface BoletoModelInterface {
  uuid: string
  temDiferencaValor: boolean
  valor: number
  ultimoValor: number
  pagador: {
    emails_cobranca: string
  }
}

interface AgendamentoStatusProps {
  id: string
  agendamentoId: string
  agendamentoNome: string
  agendamentoTipoData: number
  agendamentoHoraEnvio: number
  agendamentoEnvioAutomatico: boolean
  status: number
  mensagemErro: string
}

export interface EmailConsultaInterface {
  agendamentosStatus: AgendamentoStatusProps[]
  id: string
  origem: number
  origemDesc: string
  status: number
  statusDesc: string
  dataAlteracao: string
  dataRegistro: string
  dataEmissao: string
  dataVencimento: string
  dataPagamento: string
  empresaId: string
  empresaNome: string
  convenioId: string
  convenioDescricao: string
  convenioBancoCodigo: number
  convenioAgencia: number
  convenioContaCorrente: number
  pagadorCNPJCPF: string
  pagadorNome: string
  pagadorCidadeNome: string
  pagadorUF: string
  pagadorEmail: string
  nossoNumero: string
  documentoSistema: string
  valorTitulo: number
  valorMultaCobrada: number
  valorDescontoConcedido: number
  valorPago: number
  erroEnvioEmail: boolean
}

export interface FilterProps {
  empresaId?: string
  convenioId?: string | null
  tipoData?: number
  dataInicio?: string
  dataFim?: string

  tipo?: string
  nomeCliente?: string | null
  somenteAberta?: boolean
  somenteNaoEnviadas?: boolean
  inicio?: string
  fim?: string
  modo?: string
  empresa?: number
}

export interface BoletoFilters {
  dataInicio: FilterOptions<string>
  dataFim: FilterOptions<string>
  empresa?: FilterOptions<EmpresaModel | null>
  empresaId?: FilterOptions<string>
  convenio?: FilterOptions<ConvenioModel | null>
  convenioId?: FilterOptions<string | null>
  tipoData: FilterOptions<number>
}

export interface BoletoFilters1 {
  dataInicio: string
  dataFim: string
  empresa?: EmpresaModel | null
  empresaId?: string
  convenio?: ConvenioModel | null
  convenioId?: string
  tipoData: string
}

export const filters: BoletoFilters = {
  dataInicio: {
    label: 'Início',
    value: moment().startOf('month').format('YYYY-MM-DD'),
  },
  dataFim: {
    label: 'Fim',
    value: moment().endOf('month').format('YYYY-MM-DD'),
  },
  empresa: {
    label: 'Empresa',
  },
  empresaId: {
    label: 'Empresa',
  },
  convenio: {
    label: 'Convênio',
  },
  convenioId: {
    label: 'Convênio',
  },
  tipoData: {
    label: 'Tipo data',
    value: 0,
  },
}

export interface SortProps {
  column: string
  direction: string
}

interface EnviarBoletosManualmenteProps {
  agendamentoId: string
  boletosIds: string[]
}

export async function obterBoletos(filters: BoletoFilters1, sort?: SortProps) {
  const response = await api.post('EmailConsulta/Consultar', filters)
  return response.data.data
}

export async function deletarBoleto(
  boleto: BoletoModelInterface,
): Promise<BoletoModelInterface> {
  await api.delete(`boleto/${boleto.uuid}`)
  return boleto
}

export async function enviarBoletosManualmente(
  data: EnviarBoletosManualmenteProps,
) {
  const { agendamentoId, boletosIds } = data

  const response = await api.post('EmailEnvio/EnviarManual', boletosIds, {
    params: { agendamentoId },
  })
  return response.data
}

export function useQuery(filters: BoletoFilters1, sort?: SortProps) {
  return useTQuery<Array<EmailConsultaInterface>, AxiosError>(
    ['BOLETOS', filters, sort],
    () => {
      return obterBoletos(filters, sort)
    },
    {
      staleTime: 0,
    },
  )
}

export function useDelete() {
  const notifications = useNotifications()
  const queryClient = useQueryClient()

  return useMutation<any, AxiosError, BoletoModelInterface>(deletarBoleto, {
    onSuccess: async (boleto: BoletoModelInterface) => {
      await queryClient.cancelQueries(['BOLETOS'])

      queryClient.setQueriesData<Array<BoletoModelInterface>>(
        ['BOLETOS'],
        (old) => {
          const newData = old?.filter(
            (b: BoletoModelInterface) => b.uuid !== boleto.uuid,
          )
          return newData
        },
      )
      notifications.notifySuccess('O boleto foi excluído com sucesso')
    },
    onError: (err) => {
      notifications.notifyException(err)
    },
  })
}

export function useDownloadBoleto() {
  const { progress, start, stop, isDownloading, updateProgress } = useDownload()
  const notifications = useNotifications()
  const download = async (boleto: BoletoModelInterface) => {
    try {
      start()
      const response = await api.get(`download-boleto/${boleto.uuid}`, {
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          updateProgress(loaded, total)
        },
      })
      var fileURL = window.URL.createObjectURL(response.data)
      let tab = window.open()
      if (tab) {
        tab.location.href = fileURL
      }
    } catch (err) {
      notifications.notifyError('Não foi possível fazer o download do boleto')
    } finally {
      stop()
    }
  }

  return { download, progress, isDownloading }
}

export function useEnviarBoletosManualmente() {
  const notifications = useNotification()

  return useMutation<
    HttpResponseInterface<any>,
    AxiosError,
    EnviarBoletosManualmenteProps
  >((data) => enviarBoletosManualmente(data), {
    onSuccess: () => {
      notifications.notifySuccess('E-mails enviados com sucesso!')
    },
    onError: (error) => {
      notifications.notifyException(error)
    },
  })
}

export default function useBoletos() {
  return {
    useQuery,
    useDelete,
    useDownloadBoleto,
    useEnviarBoletosManualmente,
  }
}
