import { toast } from 'react-toastify'
import axios, { AxiosError } from 'axios'

export default function useNotification() {
  const notifySuccess = (message: string) => {
    toast.success(message)
  }

  const notifyInfo = (message: string) => {
    toast.info(message)
  }

  const notifyWarning = (message: string) => {
    toast.warning(message)
  }

  const notifyError = (message: string) => {
    toast.error(message)
  }

  const notifyException = (e: any) => {
    if (axios.isAxiosError(e)) {
      axiosError(e)
    } else {
      toast.error(e.message)
    }
  }

  //   const notifyAction = (message: string, action: SnackbarAction) => {
  //     enqueueSnackbar(message, { variant: 'default', action })
  //   }

  function axiosError(e: any) {
    const axiosError = e as AxiosError
    const defaultMessage = 'An unknown error has occurred'

    const mappedStatusErrorsMessages: { [key: number]: string } = {
      // 403: 'Suas credenciais não estão corretas',
    }

    const status: number = axiosError.request.status as number

    if (mappedStatusErrorsMessages[status]) {
      toast.error(mappedStatusErrorsMessages[status])
      return
    }

    const responseMessage = Array.isArray(e.response?.data?.errors)
      ? e.response?.data?.errors[0]
      : defaultMessage

    if (
      responseMessage?.length > 0 &&
      responseMessage !== 'An unknown error has occurred'
    ) {
      if (e.response?.status >= 400 && e.response?.status <= 500) {
        toast.error(responseMessage)
        return
      }
    }

    if (import.meta.env.VITE_ENV === 'development') {
      toast.error(
        e.response ? e.response?.data?.message || e.message : e.message,
      )
      return
    }

    if (e.response?.status >= 400 && e.response?.status <= 499) {
      toast.error(e.response?.data?.message || defaultMessage)
      return
    }

    toast.error(defaultMessage)
  }

  return {
    notifySuccess,
    notifyInfo,
    notifyWarning,
    notifyError,
    // notifyAction,
    notifyException,
  }
}
