import { AppBar, Stack, styled, Toolbar } from '@mui/material'
import { Box } from '@mui/system'

import ProfileMenu from './components/ProfileMenu'

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar)

export default function Header() {
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: '100%',
          background: (theme) => theme.palette.background.default,
          boxShadow: 'none',
        }}
      >
        <Toolbar>
          <Stack width="100%" direction="row" justifyContent="space-between">
            <Box />
            <ProfileMenu />
          </Stack>
        </Toolbar>
      </AppBar>
      <Offset />
    </>
  )
}
