import { useMemo, useCallback } from 'react'
import Options from '@components/DataTable/components/Options'
import { MUIDataTableColumnDef } from 'mui-datatables'
import DataTable from '@components/DataTable'
import useTemplateEmail, {
  RegraEnvioInterface,
  TemplateEmailInterface,
  tipoDataReverseMapping,
} from '@hooks/queries/useTemplateEmail'
import { useFormApi } from '@data-c/providers'

function definirOperacao(quantidadeDias: number) {
  let operacao: 'add' | 'subtract' | 'now' = 'now'

  if (quantidadeDias > 0) {
    operacao = 'add'
  }

  if (quantidadeDias < 0) {
    operacao = 'subtract'
  }

  return operacao
}

const buildDisparoText = (data: TemplateEmailInterface) => {
  if (!data.envioAutomatico) {
    return 'Envio manual'
  }

  const campoMaps = {
    registro: 'Registro',
    vencimento: 'Vencimento',
    pagamento: 'Pagamento',
  }
  const tipoData = data?.tipoData || 0
  const quantidadeDias = data?.quantidadeDias || 0
  const campo = tipoDataReverseMapping[tipoData]
  const operacaoDefinida = definirOperacao(quantidadeDias)

  if (data?.quantidadeDias === undefined || data?.quantidadeDias === null) {
    return 'Configuração inválida'
  }

  let operacao = ''

  if (operacaoDefinida === 'now') {
    operacao = `na data de ${campoMaps[campo || 'registro']}`
  } else {
    operacao = `${(data?.quantidadeDias || '---')
      .toString()
      .replace(/-/g, '')} dia${(data?.quantidadeDias || 0) > 1 ? 's' : ''} ${
      operacaoDefinida === 'subtract' ? 'antes' : 'depois'
    } da data de ${campoMaps[campo || 'registro']}`
  }

  return `O envio dos e-mails irá ocorrer ${operacao} de cada boleto às ${data.horaEnvio}h`
}

interface TableProps {
  onOpenTemplateEmail: (data: TemplateEmailInterface) => void
}

export default function Table(props: TableProps) {
  const { onOpenTemplateEmail } = props

  const { useQuery, obterTemplatePorId } = useTemplateEmail()
  const { data, isLoading, isFetching } = useQuery({ query: '' })
  //   const { formatDate, formatCurrency } = useUtils()

  const { openForm: opemFormRegrasEnvio, openConfirm } =
    useFormApi<TemplateEmailInterface>()

  const handleClickItem = useCallback(
    async (
      event: 'regras_envio' | 'template_email' | 'delete',
      data: TemplateEmailInterface,
    ) => {
      switch (event) {
        case 'regras_envio':
          const responseRegraEnvio = await obterTemplatePorId(data?.id || '')

          const tipoData = responseRegraEnvio?.tipoData || 0
          const quantidadeDias = responseRegraEnvio?.quantidadeDias || 0
          const somenteBoletosLiquidados =
            responseRegraEnvio?.somenteBoletosLiquidados

          const campo = tipoDataReverseMapping[tipoData]
          const liquidado = Boolean(somenteBoletosLiquidados)
          const operacao = definirOperacao(quantidadeDias)

          const regraEnvio: RegraEnvioInterface = {
            campo,
            liquidado,
            operacao,
          }

          opemFormRegrasEnvio({ ...responseRegraEnvio, regraEnvio })
          break
        case 'template_email':
          const responseTemplateEmail = await obterTemplatePorId(data?.id || '')
          onOpenTemplateEmail(responseTemplateEmail)
          break
        case 'delete':
          openConfirm(data)
          break
      }
    },
    [opemFormRegrasEnvio, onOpenTemplateEmail, openConfirm, obterTemplatePorId],
  )

  const findData = useCallback(
    (value: string): TemplateEmailInterface | undefined => {
      return data?.data?.find((d: TemplateEmailInterface) => d.id === value)
    },
    [data],
  )
  const templates =
    data?.data?.map((d) => ({
      ...d,
      disparo: buildDisparoText(d),
      horaEnvio: d.envioAutomatico ? `${d.horaEnvio}h` : '---',
    })) || []

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'identificador',
        label: 'Identificador',
        options: {
          setCellProps: () => ({
            style: {
              width: '200px',
            },
          }),
        },
      },
      {
        name: 'nome',
        label: 'Nome do Template',
      },
      {
        name: 'disparo',
        label: 'Disparo do envio',
        options: {
          setCellProps: () => ({
            style: {
              // width: '200px',
              // textAlign: 'right',
            },
          }),
        },
      },
      {
        name: 'id',
        label: 'Opções',
        options: {
          setCellProps: () => ({
            style: {
              width: '150px',
              textAlign: 'right',
            },
          }),
          customBodyRender: (value) => {
            const _data = findData(value)
            return (
              <Options
                displayUpdateButton={false}
                extraOptions={[
                  {
                    id: 'regras_envio',
                    icon: 'add_alarm',
                    visible: _data?.envioAutomatico,
                  },
                  {
                    id: 'template_email',
                    icon: 'receipt',
                    displayBadge: !_data?.corpoEmail && !_data?.tituloEmail,
                  },
                ]}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [findData, handleClickItem],
  )

  return (
    <DataTable
      data={templates}
      isLoading={isLoading}
      isFetching={isFetching}
      columns={columns}
      options={{
        pagination: false,
      }}
    />
  )
}
