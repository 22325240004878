import React, { useEffect, useState } from 'react'
import useCredentials from '@hooks/useCredentials'
import { useNavigate } from 'react-router-dom'
import { Box, styled } from '@mui/material'
import { AmbienteProps, AmbienteContext } from 'contexts/ambiente'
import Menu, { MenuItemProps } from '@components/Menu'
import Header from '@components/Header'

const toolBarHeight = 48

interface LayoutAutenticadoProps {
  children?: React.ReactNode
}

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar)

const menuItems: Array<MenuItemProps> = [
  {
    id: '1',
    label: 'Boletos',
    icon: 'request_quote',
    link: '/boletos',
  },
  {
    id: '2',
    label: 'Templates',
    icon: 'margin',
    link: '/templates',
  },
  {
    id: '3',
    label: 'Agendamentos',
    icon: 'mail',
    link: '/modelos',
  },
  // {
  //   id: '4',
  //   label: 'Histórico de Envios',
  //   icon: 'history',
  //   link: '/log',
  // },
  // {
  //   id: '2',
  //   label: 'Config',
  //   icon: 'settings',
  //   items: [
  //     {
  //       id: '3',
  //       label: 'Modelos de E-mails',
  //       icon: 'mail',
  //       link: '/templates',
  //     },
  //   ],
  // },
]

const LayoutAutenticado = (props: LayoutAutenticadoProps) => {
  const [ambiente, setAmbiente] = useState<AmbienteProps | null>(null)
  const navigate = useNavigate()
  const { isAuthenticated } = useCredentials()

  const trocarAmbiente = (ambienteNovo: AmbienteProps | null) => {
    if (ambienteNovo) {
      const ambienteAsJSON = JSON.stringify(ambienteNovo)
      localStorage.setItem(
        '@datac-emissor-boletos:ambiente-1.0.0',
        ambienteAsJSON,
      )
    } else {
      localStorage.removeItem('@datac-emissor-boletos:ambiente-1.0.0')
    }
    setAmbiente(ambienteNovo)
  }

  useEffect(() => {
    if (!isAuthenticated) {
      return navigate('/')
    }
  }, [isAuthenticated, navigate])

  useEffect(() => {
    const storedAmbienteAsJSON = localStorage.getItem(
      '@datac-emissor-boletos:ambiente-1.0.0',
    )
    if (storedAmbienteAsJSON) {
      setAmbiente(JSON.parse(storedAmbienteAsJSON))
    }
  }, [])

  return (
    <Box sx={{ display: 'flex', paddingTop: `${toolBarHeight}px` }}>
      <AmbienteContext.Provider value={{ ambiente, trocarAmbiente }}>
        <Header />
        <Menu menus={menuItems} />
        <Offset />
        <Box
          sx={{
            width: '100%',
            height: '100%',
            mt: 2,
          }}
        >
          {props.children}
        </Box>
      </AmbienteContext.Provider>
    </Box>
  )
}

export default LayoutAutenticado
