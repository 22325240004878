import jwtDecode from 'jwt-decode'
import useApi from '@hooks/useApi'
import { useNavigate } from 'react-router-dom'
import credentialsConfigs from '@configs/credentials'

export interface Empresa {
  configs?: any
  documento: string
  nome: string
  uuid: string
}

export interface LoginDataInterface {
  empresas?: Array<Empresa>
  token: string
  userId: string
  userName: string
  email?: string
  password?: string
}

export interface AmbienteDataInterface {
  // accessToken: string
  // userToken: {
  //   email: string
  //   id: string
  // }
  token: string
}

export default function useCredentials() {
  const { post, put, isSubmitting } = useApi({
    errorHandler: 'notification',
    api: credentialsConfigs.authApi,
  })
  const navigate = useNavigate()
  //const [isSubmitting, setSubmitting] = useState<boolean>(false)

  const appPermissionsIds = import.meta.env.VITE_ID as string
  const appPermissionId =
    appPermissionsIds.split(',').find((i) => i == 'stella-hubbank') ||
    'stella-hubbank'

  function setToken(token: string) {
    localStorage.setItem(credentialsConfigs.tokenKey, token)
  }

  function logout() {
    localStorage.removeItem(credentialsConfigs.tokenKey)
    navigate('/')
  }

  function getToken() {
    return localStorage.getItem(credentialsConfigs.tokenKey)
  }

  function decodeToken() {
    const token = getToken()

    if (!token) return null

    const { data } = jwtDecode<any>(token)
    return data
  }

  function isLogged() {
    const token = getToken()
    if (token === null) return false
    try {
      const { exp } = jwtDecode<any>(token)
      const expDate = new Date(exp * 1000).getTime()
      const nowDate = new Date().getTime()
      return nowDate < expDate
    } catch (err) {
      return false
    }
  }

  async function login(
    email: string,
    password: string,
  ): Promise<LoginDataInterface | undefined> {
    const response = await post<LoginDataInterface>(
      'sessions',
      {
        email,
        password,
      },
      {
        headers: {
          'DC-SISTEMA': appPermissionsIds,
        },
      },
    )

    const toReturn = response?.data
    if (toReturn) {
      const token = response.data.token
      const { data: decodedToken } = jwtDecode<any>(token)
      const permissoes = decodedToken.permissoes
      const permissao = permissoes[appPermissionId]

      toReturn.email = email
      toReturn.password = password
      toReturn.empresas = permissao.empresas
    }

    return toReturn
  }

  async function alterarSenha(
    senhaAtual: string,
    senhaNova: string,
  ): Promise<any> {
    const token = getToken()
    if (token === null) throw new Error('Não foi possível alterar sua senha')

    const response = await put(
      `passwords/change`,
      {
        old: senhaAtual,
        new: senhaNova,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    if (!response) {
      throw new Error('Ocorreu um erro ao tentar alterar sua senha')
    }

    return response?.data
  }

  async function selecionarLicenca(
    loginData?: LoginDataInterface,
    empresaId?: string,
  ): Promise<AmbienteDataInterface | undefined> {
    if (!loginData) {
      throw new Error('Login indefinido')
    }

    const response = await post<AmbienteDataInterface>(
      `selecionar-empresa`,
      {
        permissao: appPermissionId,
        empresaUuid: empresaId,
      },
      {
        headers: {
          Authorization: 'Bearer ' + loginData.token,
        },
      },
    )

    if (!response) {
      throw new Error('Erro no login')
    }

    const { token } = response.data
    setToken(token)
    return response.data
  }

  const isAuthenticated: Boolean = isLogged()
  const userLogged = decodeToken()
  const token = getToken()

  return {
    login,
    logout,
    isSubmitting,
    token,
    userLogged,
    isAuthenticated,
    selecionarLicenca,
    alterarSenha,
  }
}
