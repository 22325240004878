import { HttpResponseInterface } from '@data-c/hooks'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import ErrorInterface from 'interfaces/ErrorInterface'
import api from '@services/api'

export interface ConvenioModel {
  id: string
  empresaId: string
  convenioDesc: string
  empresaCNPJCPF: string
  empresaNome: string
  bancoCodigo: number
  bancoNomeDesc: string
  ativo: boolean
  convenioAgencia: number
  convenioConta: number
  convenioNumero: string
  carteiraCodigo: string
  carteiraVariacao: string
  nossoNumeroUltimoSequencial: number
  impressaoBoletoPadraoId: string
  dataConsultaUltimoMovimento: Date
}

export async function obterConvenioPorEmpresaId(
  id: string,
): Promise<HttpResponseInterface<ConvenioModel>> {
  const response = await api.get<HttpResponseInterface<ConvenioModel>>(
    'Convenio/ObterAtivosPorEmpresaId',
    {
      params: { empresaId: id },
    },
  )
  return response.data
}

export function useQueryObterConvenioPorEmpresaId(id: string) {
  return useQuery<
    HttpResponseInterface<ConvenioModel>,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['CONVENIO', id], () => {
    return obterConvenioPorEmpresaId(id)
  })
}

export default function useConvenio() {
  return {
    useQueryObterConvenioPorEmpresaId,
    obterConvenioPorEmpresaId,
  }
}
