import { useCallback } from 'react'

import { Container, LinearProgress, Typography } from '@mui/material'

import { ButtonContainer, PageTitle } from '@data-c/ui'
import { FilterProvider, FormProvider } from '@data-c/providers'

import useBoletos, {
  BoletoModelInterface,
  filters,
} from '@hooks/queries/useBoletos'
import useDialog from '@hooks/useDialog'

import ConfirmDialog from '@components/ConfirmDialog'
import Dialog, { DialogContent } from '@components/Dialog'

import Filter from './components/Filter'
import Table from './components/Table'
import Form from './components/Form'

export default function Boletos() {
  const { data, isOpen, closeDialog, openDialog } =
    useDialog<BoletoModelInterface>()

  const { useDownloadBoleto, useDelete } = useBoletos()
  const { mutateAsync: mutate, isLoading: isDeletting } = useDelete()
  const { download, isDownloading, progress } = useDownloadBoleto()

  const handleClickOptionsRow = useCallback(
    (event: string, _data: BoletoModelInterface) => {
      const handleDownloadBoleto = (_data: BoletoModelInterface) => {
        download(_data)
      }

      const handleDeletarBoleto = (_data: BoletoModelInterface) => {
        openDialog(_data)
      }

      const maps: Record<string, (value: BoletoModelInterface) => void> = {
        downloadBoleto: handleDownloadBoleto,
        delete: handleDeletarBoleto,
      }

      maps[event](_data)
    },
    [openDialog, download],
  )

  const handleDeleteBoleto = useCallback(async () => {
    if (data) {
      await mutate(data)
      closeDialog()
    }
  }, [data, mutate, closeDialog])

  return (
    <Container>
      <FormProvider>
        <FilterProvider
          filterValues={filters}
          storagePath={
            import.meta.env.VITE_STORAGE_PATH || '@storage:stella-hubbank'
          }
        >
          <PageTitle title="Boletos">
            <ButtonContainer>
              <Filter />
            </ButtonContainer>
          </PageTitle>
          <Form />
          <Table onClickOptionsRow={handleClickOptionsRow} />
        </FilterProvider>
      </FormProvider>

      <ConfirmDialog
        confirm={handleDeleteBoleto}
        onClose={closeDialog}
        isOpen={isOpen}
        isLoading={isDeletting}
      />
      <Dialog open={isDownloading} title="Download">
        <DialogContent>
          <Typography variant="body1">Fazendo download do boleto</Typography>
          <LinearProgress variant="determinate" value={progress} />
        </DialogContent>
      </Dialog>
    </Container>
  )
}
