import { MenuItem, TextField, TextFieldProps } from '@mui/material'

export default function DropDownTipoData(props: TextFieldProps) {
  return (
    <TextField select {...props}>
      <MenuItem value="0">Vencimento</MenuItem>
      <MenuItem value="1">Pagamento</MenuItem>
      <MenuItem value="2">Registro</MenuItem>
    </TextField>
  )
}
