import { useState, useMemo } from 'react'

import {
  Box,
  TextField,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Divider,
  MenuItem,
  FormHelperText,
  FormControl,
  AlertTitle,
  Alert,
  Switch,
  FormControlLabel,
} from '@mui/material'

import { useFormApi, useFormData } from '@data-c/providers'
import { Button, ButtonContainer, FormContainer } from '@data-c/ui'

import useTemplateEmail, {
  TemplateEmailInterface,
  tipoDataMapping,
} from '@hooks/queries/useTemplateEmail'
import useValidations from '@hooks/useValidations'
import useUtils from '@hooks/useUtils'

import ChooseOne from '@components/ChooseOne'
import * as yup from 'yup'

const campoMaps = {
  registro: 'Criação',
  vencimento: 'Vencimento',
  pagamento: 'Pagamento',
}

const horas = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
]

const validationSchema = yup.object().shape({
  nome: yup.string().required('Informe o Nome do Modelo'),
  identificador: yup.string().required('Informe o Identificador'),
  // .test(
  //   'unique Id',
  //   'Identificador já está sendo utilizado',
  //   async function () {
  //     return false
  //   },
  // ),
})

const step1ValidationSchema = yup.object().shape({
  horaEnvio: yup.number().required('Informe a Hora do envio'),
})

const step2ValidationSchema = yup.object().shape({
  campo: yup.string().required('Informe a data referência para o envio'),
})

const step3ValidationSchema = yup.object().shape(
  {
    quantidadeDias: yup
      .string()
      .ensure()
      .when('operacao', {
        is: (val: string) => val !== 'now',
        then: yup.string().required('Informe os dias'),
      }),
    operacao: yup.string().required('Informe a operação'),
  },
  [['quantidadeDias', 'operacao']],
)

const Form = () => {
  const [activeStep, setActiveStep] = useState<number>(0)

  const { snakeCase } = useUtils()
  const { validationProps, setValidationErrors } = useValidations()
  const {
    changeValue,
    onChangeFormValue,
    changeValues,
    toggleSubmit,
    closeForm,
  } = useFormApi<TemplateEmailInterface>()
  const { formValues: data } = useFormData<TemplateEmailInterface>()

  const { useSubmit } = useTemplateEmail()
  const { mutateAsync: salvarTemplate, isLoading } = useSubmit()

  function handleSubmit() {
    setValidationErrors(null)
    validationSchema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        let quantidadeDias = data?.quantidadeDias || 0

        const operacaoMapping: { [key: string]: number } = {
          add: quantidadeDias,
          subtract: -quantidadeDias,
          now: 0,
        }

        const operacao = data?.regraEnvio?.operacao || ''
        quantidadeDias = operacaoMapping[operacao]

        await salvarTemplate({ ...data, quantidadeDias })

        changeValues({ nome: '', identificador: '', envioAutomatico: true })
        setActiveStep(0)

        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => {
        toggleSubmit(false)
      })
  }

  const handleBlurNomeTemplate = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!data.identificador) {
      changeValue('identificador', snakeCase(data.nome.trim()))
    }
  }

  const handleNext = () => {
    const nextStep = () => {
      setActiveStep(activeStep + 1)
    }
    if (activeStep === 2) {
      setValidationErrors(null)
      step1ValidationSchema
        .validate(data, { abortEarly: false })
        .then(async () => {
          nextStep()
        })
        .catch((err) => {
          setValidationErrors(err)
        })
      return
    }

    if (activeStep === 3) {
      setValidationErrors(null)
      step2ValidationSchema
        .validate(data.regraEnvio, { abortEarly: false })
        .then(async () => {
          nextStep()
        })
        .catch((err) => {
          setValidationErrors(err)
        })
      return
    }

    if (activeStep === 4) {
      setValidationErrors(null)
      step3ValidationSchema
        .validate(
          {
            quantidadeDias: data?.quantidadeDias,
            operacao: data?.regraEnvio?.operacao,
          },
          { abortEarly: false },
        )
        .then(async () => {
          nextStep()
        })
        .catch((err) => {
          setValidationErrors(err)
        })
      return
    }

    nextStep()
  }

  const handlePrevious = () => {
    setActiveStep(activeStep - 1)
  }

  const quantidadeDiasFormatado = (data?.quantidadeDias || '---')
    .toString()
    .replace(/-/g, '')

  const mensagemDeRevisao = useMemo(() => {
    let operacao = ''
    if (data.regraEnvio?.operacao === 'now') {
      operacao = `na data de ${campoMaps[data.regraEnvio.campo || 'registro']}`
    } else {
      operacao = `${quantidadeDiasFormatado} dia${
        (data?.quantidadeDias || 0) > 1 ? 's' : ''
      } ${
        data.regraEnvio?.operacao === 'subtract' ? 'antes' : 'depois'
      } da data de ${campoMaps[data?.regraEnvio?.campo || 'registro']}`
    }

    return `O envio dos e-mails irá ocorrer ${operacao} de cada boleto às ${data.horaEnvio}h`
  }, [data, quantidadeDiasFormatado])

  const campoValidationProps = validationProps('campo')
  const operacaoValidationProps = validationProps('operacao')

  return (
    <FormContainer
      dialogProps={{ maxWidth: 'md', title: 'Agendamento' }}
      triggerButton="none"
      actions={
        <ButtonContainer>
          {data.envioAutomatico && (
            <>
              <Button
                isLoading={isLoading}
                disabled={isLoading || activeStep === 0}
                onClick={handlePrevious}
              >
                Voltar
              </Button>
              <Button
                variant="contained"
                isLoading={isLoading}
                disabled={isLoading || activeStep === 5}
                onClick={handleNext}
              >
                Próximo
              </Button>
            </>
          )}
          {(!data.envioAutomatico || activeStep === 5) && (
            <Button
              variant="contained"
              isLoading={isLoading}
              disabled={isLoading}
              onClick={handleSubmit}
            >
              Salvar
            </Button>
          )}
        </ButtonContainer>
      }
    >
      <Box
        sx={{
          mb: 2,
          minHeight: '200px',
        }}
      >
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <TextField
              autoFocus
              onChange={onChangeFormValue}
              onBlur={handleBlurNomeTemplate}
              name="nome"
              label="Nome do Modelo"
              value={data?.nome || ''}
              required
              {...validationProps('nome')}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <TextField
              required
              onChange={onChangeFormValue}
              name="identificador"
              label="Identificador"
              value={data?.identificador || ''}
              {...validationProps('identificador')}
              disabled={Boolean(data?.id)}
            />
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <ChooseOne
              onChoose={(item) => {
                if (item.value === 'manual') {
                  changeValue('envioAutomatico', false)
                  changeValue('regraEnvio', null)
                } else {
                  changeValue('envioAutomatico', true)
                  if (!data.regraEnvio) {
                    changeValue('regraEnvio', {
                      liquidado: false,
                      campo: 'vencimento',
                      operacao: 'subtract',
                    })
                  }
                }
              }}
              value={data?.envioAutomatico ? 'automatico' : 'manual'}
              options={[
                {
                  title: 'Envio Manual',
                  value: 'manual',
                  description:
                    'Você cria os agendamentos e os envia quando quiser.',
                  icon: 'touch_app',
                },
                {
                  title: 'Envio Automático',
                  value: 'automatico',
                  description:
                    'Você cria os agendamentos e o sistema envia os e-mails para você automaticamente.',
                  icon: 'precision_manufacturing',
                },
              ]}
            />
          </Grid>

          {data?.envioAutomatico && (
            <>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Divider />
              </Grid>
              {activeStep === 0 && (
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <ChooseOne
                    onChoose={(item) => {
                      const value = item.value
                      const liquidado = value === 'liquidado'
                      changeValue('somenteBoletosLiquidados', liquidado)
                    }}
                    value={data?.regraEnvio?.liquidado ? 'liquidado' : 'aberto'}
                    options={[
                      {
                        title: 'Apenas Boletos em Aberto',
                        value: 'aberto',
                        description:
                          'Apenas os boletos em aberto serão considerados no envio do e-mail.',
                        icon: 'credit_card_off',
                      },
                      {
                        title: 'Apenas Boletos Liquidados',
                        value: 'liquidado',
                        description:
                          'Apenas os boletos liquidados serão considerados no envio do e-mail.',
                        icon: 'credit_score',
                      },
                    ]}
                  />
                </Grid>
              )}

              {activeStep === 1 && (
                <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
                  <Box sx={{ width: '100%', textAlign: 'center' }}>
                    <FormControlLabel
                      label="Anexar boleto"
                      labelPlacement="bottom"
                      control={
                        <Switch
                          name="anexarPDF"
                          size="medium"
                          checked={data?.anexarPDF || false}
                          onChange={onChangeFormValue}
                          type="checkbox"
                        />
                      }
                    />
                  </Box>
                </Grid>
              )}

              {activeStep === 2 && (
                <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
                  <TextField
                    select
                    onChange={onChangeFormValue}
                    // onBlur={handleBlurNomeTemplate}
                    name="horaEnvio"
                    label="Hora do envio"
                    value={data?.horaEnvio || ''}
                    required
                    {...validationProps('horaEnvio')}
                  >
                    {horas.map((h) => (
                      <MenuItem key={h} value={h}>{`${h}h`}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}

              {activeStep === 3 && (
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <ChooseOne
                    gridItemSize={4}
                    onChoose={(item) => {
                      changeValue('regraEnvio', {
                        ...data.regraEnvio,
                        campo: item?.value || '',
                      })

                      const key = item?.value || ''
                      const tipoData = tipoDataMapping[key]

                      changeValue('tipoData', tipoData)
                    }}
                    value={data?.regraEnvio?.campo}
                    options={[
                      {
                        title: 'Criação',
                        value: 'registro',
                        description: '',
                        icon: 'event',
                      },
                      {
                        title: 'Vencimento',
                        value: 'vencimento',
                        description: '',
                        icon: 'event',
                      },
                      {
                        title: 'Liquidação (Pagamento)',
                        value: 'pagamento',
                        description: '',
                        icon: 'event',
                      },
                    ]}
                  />
                  {campoValidationProps.error && (
                    <FormControl error>
                      <FormHelperText id="component-error-text">
                        {campoValidationProps.helperText}
                      </FormHelperText>
                    </FormControl>
                  )}
                </Grid>
              )}

              {activeStep === 4 && (
                <>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <ChooseOne
                      gridItemSize={4}
                      onChoose={(item) => {
                        changeValue('regraEnvio', {
                          ...data.regraEnvio,
                          operacao: item?.value || '',
                        })
                      }}
                      value={data?.regraEnvio?.operacao}
                      options={[
                        {
                          title: 'No dia',
                          value: 'now',
                          description: `O envio ocorre na data de ${data.regraEnvio?.campo}`,
                          icon: 'today',
                        },
                        {
                          title: 'Antes',
                          value: 'subtract',
                          description: data.quantidadeDias
                            ? `O envio ocorre ${quantidadeDiasFormatado} dia${
                                (data?.quantidadeDias || 0) > 1 ? 's' : ''
                              } antes da data de ${
                                campoMaps[data?.regraEnvio?.campo || 'registro']
                              }`
                            : 'Configure os dias para obter um resultado',
                          icon: 'schedule',
                        },
                        {
                          title: 'Depois',
                          value: 'add',
                          description: data.quantidadeDias
                            ? `O envio ocorre ${quantidadeDiasFormatado} dia${
                                (data?.quantidadeDias || 0) > 1 ? 's' : ''
                              } depois da data de ${
                                campoMaps[data?.regraEnvio?.campo || 'registro']
                              }`
                            : 'Configure os dias para obter um resultado',
                          icon: 'schedule',
                        },
                      ]}
                    />
                    {operacaoValidationProps.error && (
                      <FormControl error>
                        <FormHelperText id="opcomponent-error-text">
                          {operacaoValidationProps.helperText}
                        </FormHelperText>
                      </FormControl>
                    )}
                  </Grid>

                  {data?.regraEnvio?.operacao !== 'now' && (
                    <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
                      <TextField
                        onChange={(e) => {
                          const quantidadeDias = e.target.value
                          changeValue('quantidadeDias', quantidadeDias)
                        }}
                        onBlur={handleBlurNomeTemplate}
                        name="quantidadeDias"
                        label="Dias"
                        // value={data?.quantidadeDias || ''}
                        value={quantidadeDiasFormatado}
                        required
                        {...validationProps('quantidadeDias')}
                      />
                    </Grid>
                  )}
                </>
              )}

              {activeStep === 5 && (
                <Box sx={{ mt: 2 }}>
                  <Alert severity="info">
                    <AlertTitle>Revise as configurações</AlertTitle>
                    {mensagemDeRevisao}
                  </Alert>
                </Box>
              )}

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ mt: 1 }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  <Step>
                    <StepLabel>
                      Vamos enviar os boletos em aberto ou os liquidados
                      (pagos)?
                    </StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>
                      Você quer anexar o arquivo PDF do boleto ao e-mail?
                    </StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>
                      Qual a hora do dia o envio dos e-mails deve ocorrer?
                    </StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>
                      A partir de qual data você quer realizar o envio dos
                      e-mails?
                    </StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>
                      Quando o evento de envio dos e-mails deve ocorrer?.
                    </StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>
                      Veja se está tudo certinho e clique em salvar!
                    </StepLabel>
                  </Step>
                </Stepper>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </FormContainer>
  )
}

Form.defaultProps = {
  data: {},
}

export default Form
