import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { MenuItemProps, MenuProps } from '@components/Menu'
import { List, Icon, ListItemIcon, Popper } from '@mui/material'

import FullMenu from '../FullMenu'
import MenuItem from '../MenuItem'

import { theme } from '@data-c/ui'

interface MinMenuProps extends MenuProps {}

interface OpenedStateProps {
  element: HTMLElement
  id: string
  items?: Array<MenuItemProps>
}

export default function MinMenu(props: MinMenuProps) {
  const { menus } = props

  const [opened, setOpened] = useState<OpenedStateProps | null>(null)

  const handleOpen = (
    event: React.MouseEvent<HTMLElement>,
    id: string,
    items?: Array<MenuItemProps>,
  ) => {
    setOpened({
      element: event.currentTarget,
      id,
      items,
    })
  }

  const handleClose = () => {
    setOpened(null)
  }

  const navigate = useNavigate()
  const location = useLocation()

  return (
    <List component="nav" disablePadding>
      {menus.map((menuItem) => {
        return (
          <MenuItem
            key={`minMenu-${menuItem.id}`}
            onMouseEnter={(e: React.MouseEvent<HTMLElement>) => {
              if (!menuItem?.link) {
                handleOpen(e, menuItem.id, menuItem.items)
              }
            }}
            onMouseLeave={() => {
              if (!menuItem?.link) {
                handleClose()
              }
            }}
            onClick={() => {
              if (menuItem?.link) {
                navigate(menuItem.link)
              }
            }}
            selected={location.pathname === menuItem.link}
          >
            <ListItemIcon>
              <Icon>{menuItem.icon}</Icon>
            </ListItemIcon>
            {opened?.id === menuItem.id && (
              <Popper
                sx={{
                  zIndex: 999999,
                }}
                anchorEl={opened?.element}
                open={Boolean(opened?.element)}
                placement="right-start"
              >
                <FullMenu
                  menus={menuItem?.items || []}
                  sx={{
                    minWidth: '240px',
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: '12px',
                    padding: '16px 4px',
                    boxShadow:
                      '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);',
                  }}
                />
              </Popper>
            )}
          </MenuItem>
        )
      })}
    </List>
  )
}
