import { ReactNode, useState } from 'react'

import { Box, Drawer as MuiDrawer } from '@mui/material'
import { CSSObject, styled, Theme } from '@mui/material/styles'

import Logo from '@components/Logo'

import FullMenu from './componetns/FullMenu'
import MinMenu from './componetns/MinMenu'

import ToogleMenuButton from './componetns/ToogleMenuButton'

const drawerWidth = 260

export interface MenuItemProps {
  id: string
  label: string
  items?: MenuItemProps[]
  link?: string
  icon?: ReactNode
  isFirstLevel?: boolean
}

export interface MenuProps {
  menus: MenuItemProps[]
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(8)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  bgcolor: theme.palette.background.paper,
  boxShadow: '2px 3px 20px -16px rgb(32 32 36 / 30%)',
  borderRightStyle: 'none',
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

export default function Menu(props: MenuProps) {
  const { menus } = props

  const [open, setOpen] = useState<boolean>(true)

  function handleToogleDrawerMenu() {
    setOpen(!open)
  }

  return (
    <Drawer variant="permanent" open={open} onClose={handleToogleDrawerMenu}>
      <Box sx={{ height: (theme) => theme.mixins.toolbar }}>
        {open ? <Logo /> : <Logo smallLogo />}
      </Box>

      <ToogleMenuButton
        open={open}
        onToogleDrawerMenu={handleToogleDrawerMenu}
      />

      {open && <FullMenu menus={menus} />}
      {!open && <MinMenu menus={menus} />}
    </Drawer>
  )
}
